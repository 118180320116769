import React from "react"
import Layout from "../components/common/layout"
import Meta from "../components/common/meta"
import PageTitle from "../components/pageTitle/pageTitle"

const ContactText = props => {
  if (props.state) {
    return (
      <React.Fragment>
        <p className="contact-body__text en">Your request has been sent.</p>
        <p className="contact-body__text ja">
          お問い合わせありがとうございました。
        </p>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <p className="contact-body__text en">
          We are happy to receive inquiries about any garden projects,{" "}
          <br className="pc" />
          from gardens for individual homes to landscaping for commercial
          facilities. <br className="pc" />
          For projects needing an urgent response, please phone us.
        </p>
        <p className="contact-body__text ja">
          個人住宅のお庭から、商業施設のランドスケープまで、
          <br className="pc" />
          お庭に関するご質問など、お気軽にお問い合わせ下さい。
          <br className="pc" />
          お急ぎの方は、お電話にてお願いします。
        </p>
      </React.Fragment>
    )
  }
}

const ContactForm = props => {
  if (props.state) {
    return ""
  }
  return (
    <form
      name="contact_form"
      className="contact-form"
      action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSf9rlyvG8wghAGx6gq15qjKd5S4myoWA3WFz7OGBm-8MG_TyQ/formResponse"
      method="POST"
      target="sendMessage"
    >
      <div className="contact-form__block contact-form__block--input">
        <dt className="contact-form__title">Name</dt>
        <dd className="contact-form__detail">
          <input
            type="text"
            id="name"
            className="contact-form__input"
            name="entry.2017896521"
            required
          />
        </dd>
      </div>
      <div className="contact-form__block contact-form__block--input">
        <dt className="contact-form__title">E-mail</dt>
        <dd className="contact-form__detail">
          <input
            type="text"
            id="eMail"
            className="contact-form__input"
            name="entry.1477395797"
            required
          />
        </dd>
      </div>
      <div className="contact-form__block contact-form__block--textarea">
        <dt className="contact-form__title">Message</dt>
        <dd className="contact-form__detail">
          <textarea
            type="text"
            id="message"
            className="contact-form__textarea"
            name="entry.790636064"
            required
          />
        </dd>
      </div>
      <div className="contact-form__btn">
        <button
          onClick={props.submitClick}
          type="button"
          className="contact-form__submit"
          value="submit"
        >
          <span className="contact-form__submit-i">
            <img src="/image/form/submit_icon.png" alt="" />
          </span>
          <span className="contact-form__submit-t en">Submit</span>
        </button>
      </div>
    </form>
  )
}

class contactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submit: false,
    }
    this.checkEmailAddress = this.checkEmailAddress.bind(this)
    this.submitClick = this.submitClick.bind(this)
  }

  checkEmailAddress = str => {
    if (str.match(/.+@.+\..+/) == null) {
      return false
    } else {
      return true
    }
  }

  submitClick = () => {
    const form = document.contact_form
    let checkFlg = [false, false, false]

    const check = (tag, i) => {
      if (!document.getElementById(tag).value) {
        document.getElementById(tag).setAttribute("style", "background:#FFABAD")
        return false
      }

      if (tag === "eMail") {
        if (this.checkEmailAddress(document.getElementById(tag).value)) {
          document
            .getElementById(tag)
            .setAttribute("style", "background:#c9caca")
          checkFlg[i] = true
        } else {
          document
            .getElementById(tag)
            .setAttribute("style", "background:#FFABAD")
          return false
        }
      }

      document.getElementById(tag).setAttribute("style", "background:#c9caca")
      checkFlg[i] = true
    }

    ;["name", "eMail", "message"].forEach((v, i) => {
      check(v, i)
    })
    if (!checkFlg.includes(false)) {
      this.setState({ submit: true })
      form.submit()
      form.reset()
      return false
    }
  }
  render() {
    const submitState = this.state.submit
    return (
      <Layout page="contact">
        <Meta
          title="contact | 1moku co."
          description="個人住宅のお庭から、商業施設のランドスケープまで、お庭に関するご質問など、お気軽にお問い合わせ下さい。お急ぎの方は、お電話にてお願いします。"
          url="https://www.1moku.co.jp/contact/"
          type="article"
        />
        <PageTitle title="contact" />
        <div className="contact-container">
          <div className="contact-container__head contact-head">
            <h1 className="contact-head__in">
              <span className="contact-head__title en">Contact</span>
              <span className="contact-head__title ja">お問い合わせ</span>
            </h1>
          </div>
          <div className="contact-container__body contact-body">
            <div className="contact-body__head">
              <ContactText state={submitState} />
              <div className="contact-body__tel en">
                <p>
                  <span className="contact-body__tel-color">tel.</span>{" "}
                  <a className="contact-body__tel-link" href="tel:810364510846">
                    +81-(0)3-6451-0846
                  </a>
                </p>
                <p>
                  <span className="contact-body__tel-color">fax.</span>{" "}
                  <span className="contact-body__tel-link">
                    +81-(0)3-6451-0847
                  </span>
                </p>
              </div>
            </div>
            <div className="contact-body__form">
              <ContactForm state={submitState} submitClick={this.submitClick} />
              <iframe
                id="sendMessage"
                name="sendMessage"
                title="sendMessage"
                className="contact-form__target"
              ></iframe>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default contactPage
